@import 'bootstrap.scss';
@import 'utils.scss';
@import '~slick-carousel/slick/slick.scss';
@import '~@fancyapps/fancybox/dist/jquery.fancybox.css';
@import '~material-design-icons/iconfont/material-icons.css';

html {
    font-size: 15px;
}
body {
    font-family: 'Montserrat', Verdana, sans-serif;
    font-size: 16px;
    @media #{$media-md} {
        padding-bottom: 43px;
    }
}

$ultraSize: 2.8rem;
$biggerSize: 2rem;
$bigSize: 1.4rem;
$mediumSize: 1.333rem;
$normalSize: 1rem;
$smallSize: 0.866rem;

.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
    &--small {
        @media (min-width: 1200px) {
            max-width: 980px;
        }
    }
}

.spacer {
    flex-grow: 1 !important;
}

strong {
    font-weight: bold;
}

.button {
    display: inline-block;
    padding: 1.666rem 0.5rem;
    width: 200px;
    line-height: 1.2;
    font-size: $bigSize;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    word-spacing: 9999px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: $success;
    color: white !important;
    text-align: center;
    border-radius: 0.25rem;
    text-decoration: none !important;
    transition: $transition;
    cursor: pointer;
    overflow: hidden;
    &--alt {
        word-spacing: normal;
    }
    &:hover {
        background-color: darken($success, 5%);
    }
    &:disabled {
        cursor: wait;
        &:hover {
            background-color: $success;
        }
    }
    @media #{$media-sm} {
        font-size: $normalSize;
        padding: 1rem 0.25rem;
        width: 180px;
    }
}

.text {
    font-size: $bigSize;
    line-height: 1.2;
    img {
        max-width: 100% !important;
        height: auto !important;
    }
    p {
        margin: 0;
        padding: 0;
    }
}

.topbar {
    padding: 2rem 0;
    @media #{$media-md} {
        padding: 1.5rem 0;
    }
}

.brand {
    &__logo {
        width: 230px;
        height: auto;
        max-width: 100%;
        &--small {
            width: 200px;
        }
    }
}

.menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__alt-text {
        color: $secondary;
        font-size: 0.866rem;
        text-transform: uppercase;
    }
    &__text {
        font-size: 1.133rem;
        text-transform: uppercase;
        font-weight: 500;
        margin-left: 4rem;
        color: $dark;
        transition: $transition;
        text-decoration: none !important;
        &:hover {
            color: $primary;
        }
    }
    &__icon {
        text-decoration: none !important;
        display: flex;
        align-items: center;
        margin-left: 1.25rem;
        img {
            height: 27px;
            width: auto;
            margin-right: 0.5rem;
            filter: grayscale(100%);
            opacity: 0.75;
            transition: $transition;
        }
        span {
            font-size: 0.933rem;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 100%;
            white-space: nowrap;
            text-transform: uppercase;
            color: $secondary;
            transition: $transition;
        }
        &--active {
            img {
                filter: grayscale(0);
                opacity: 1;
            }
            span {
                color: $primary;
            }
        }
        &:hover {
            img {
                filter: grayscale(0);
                opacity: 1;
            }
            span {
                color: $primary;
            }
        }
    }
    &__lang-toggle,
    &__user-toggle {
        cursor: pointer;
        transition: $transition;
        &::after {
            color: $light;
            font-size: 0.866rem;
        }
        &:hover {
            .menu__lang-icon {
                opacity: 0.85;
            }
        }
    }
    &__lang-icon {
        width: 28px;
        height: 28px;
    }
    &__langs,
    &__user {
        .dropdown-menu {
            top: 1rem !important;
        }
        .dropdown-triangle {
            content: '';
            display: block;
            position: absolute;
            top: -8px;
            right: calc(1rem + 5px);
            width: 16px;
            height: 16px;
            background-color: white;
            transform-origin: center center;
            transform: rotate(45deg);
            box-shadow: -3px -3px 4px 0 rgba($light, 0.55);
            z-index: -1;
        }
    }
    &__user-icon {
        width: auto;
        height: 28px;
    }
    &__settings-icon {
        width: 30px;
        height: auto;
    }
    @media #{$media-lg} {
        &__text {
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
    @media #{$media-md} {
        position: fixed;
        z-index: 10000;
        width: 100%;
        justify-content: center;
        left: 0;
        bottom: 0;
        background-color: $lighter;
        padding: 0.5rem 1rem;
        &__langs,
        &__user {
            .dropdown-menu {
                top: -1rem !important;
                .dropdown-item {
                    font-size: 0.866rem !important;
                }
            }
            .dropdown-triangle {
                top: auto;
                bottom: -8px;
                box-shadow: 3px 3px 4px 0 rgba($light, 0.55);
            }
        }
        &__text {
            font-size: 0.933rem;
            text-transform: uppercase;
        }
    }
}

.auth-modal {
    &__image {
        width: 200px;
        max-width: 90%;
        height: auto;
    }
    .btn-auth {
        border-width: 2px;
        font-weight: 500;
        position: relative;
        &__icon {
            transition: all 0.3s ease-in-out;
            height: 20px;
            width: auto;
            position: absolute;
            left: 1rem;
        }
        &:hover {
            .btn-auth__icon {
                filter: brightness(0) invert(1);
            }
        }
    }
    label {
        font-size: 0.66rem;
        font-weight: 600;
        text-transform: uppercase;
        &.form-check-label {
            font-weight: 400;
            text-transform: none;
            font-size: 0.866rem;
        }
    }
}

.invalid-feedback {
    display: block;
    font-size: 0.666rem;
    padding: 0;
    margin-bottom: 3px;
    li {
        display: block;
    }
}

.grecaptcha-badge {
    display: none !important;
}

.content {
    min-height: 80vh;
}

.footermenu {
    &__item {
        padding: 0.15rem 0;
        @media #{$media-lg} {
            text-align: center;
        }
    }
    &__link {
        font-weight: 500;
        color: $dark;
        cursor: pointer;
        transition: $transition;
        &:hover {
            color: $primary;
        }
    }
}

.socialmedia {
    &__item {
        display: inline-block;
        transition: $transition;
        &:hover {
            opacity: 0.5;
        }
    }
    &__icon {
        height: 26px;
        width: auto;
    }
}

.copyrights {
    font-weight: 500;
    font-size: 0.875rem;
}

.bigmenu {
    &__img {
        width: 70px;
        height: auto;
        margin-right: 2.666rem;
    }
    &__link {
        font-size: 3.2rem;
        color: black;
        transition: $transition;
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }
    @media #{$media-lg} {
        &__img {
            width: 50px;
        }
        &__link {
            font-size: 2rem;
        }
    }
    @media #{$media-sm} {
        &__img {
            width: 40px;
        }
        &__link {
            font-size: 1.6rem;
        }
    }
}

.text-slider {
    max-width: 400px;
    &__pictures {
        height: 200px;
    }
    &__img {
        height: 100%;
    }
    &__title {
        font-size: $bigSize;
        font-weight: 600;
        text-align: left;
    }
    &__bar {
        height: 2px;
        width: 100%;
        background-color: $light;
    }
    &__progress {
        height: 100%;
        width: 0;
        background-color: $primary;
    }
    &__slide {
        text-align: left;
        p {
            margin-bottom: 0;
        }
    }
    &__content {
        transform: translateY(30px);
        transition: all 1s ease-in-out;
        opacity: 0;
    }
    &__photos {
        height: 300px;
    }
    &__photo {
        height: 100%;
    }
    .slick-current {
        .text-slider__content {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.reviews {
    &__item {
        padding: 1rem 2rem;
        transition: $transition;
    }
    .slick-slide {
        height: auto;
    }
    &__star {
        img {
            width: 30px;
            height: auto;
        }
    }
    &__text {
        font-size: 1.3rem;
        line-height: 1.5;
        margin-bottom: 1.5rem;
        p:before {
            content: open-quote;
        }
        p:after {
            content: close-quote;
        }
    }
    &__person {
        font-weight: bold;
        font-size: 1.3rem;
        color: $primary;
    }
    &__person-text {
        font-size: $bigSize;
    }
    .slick-dots {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem 0 0 0;
        padding: 0;
        li {
            display: inline-block;
            vertical-align: middle;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #d1d3d4;
            margin: 0 0.5rem;
            transition: opacity 0.3s ease-in-out;
            cursor: pointer;
            button {
                display: none;
            }
            &:hover {
                background-color: #bebebe;
            }
        }
        li.slick-active {
            background-color: $primary;
            width: 16px;
            height: 16px;
            border-radius: 8px;
        }
    }
    @media #{$media-sm} {
        &__title,
        &__text {
            font-size: 1rem;
        }
    }
}

.cutout {
    display: inline-flex;
    align-items: center;
    padding: 2rem 0;
    background-image: url('../gfx/cutout.png'), url('../gfx/cutout.png');
    background-repeat: repeat-x, repeat-x;
    background-position: left top, left bottom;
    &__picture {
        max-width: 500px;
        flex-grow: 0;
        flex-shrink: 0;
        margin-left: 2rem;
    }
    @media #{$media-sm} {
        &__text {
            font-size: 1rem;
            margin-bottom: 1rem;
            text-align: center !important;
        }
        &__picture {
            width: 100%;
            max-width: 100%;
            height: auto;
            margin-left: 0;
            text-align: center;
        }
        display: block;
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

.searchbar {
    background-color: $secondary;
    padding: 2.5rem 0;
    &__title {
        font-size: $ultraSize;
        font-weight: bold;
        color: white;
        line-height: 1.2;
        margin-bottom: 2rem;
    }
    &__control {
        margin-bottom: 1rem;
        border-color: white !important;
        box-shadow: none !important;
        padding-left: 0;
    }
    button {
        background: transparent !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        margin: 0;
        padding: 0;
        color: $secondary;
        transition: $transition;
        &:hover {
            color: $primary;
        }
    }
    @media #{$media-md} {
        padding: 2rem 0;
        &__title {
            text-align: center;
            font-size: $bigSize;
            margin-bottom: 1.5rem;
        }
    }
}

.categories {
    &__item {
        height: 100%;
        text-align: center;
        box-shadow: 0 0 10px rgba(black, 0.15);
        background-color: white;
        border-radius: 1rem;
        overflow: hidden;
        transition: $transition;
        &:hover {
            background-color: $lighter;
            box-shadow: 0 0 10px rgba(black, 0.3);
            .categories__name {
                color: $primary;
            }
        }
    }
    &__icon {
        height: 70px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
        margin-top: 3rem;
        margin-bottom: 2rem;
    }
    &__name {
        font-weight: bold;
        font-size: $bigSize;
        color: black;
        padding: 0 1.333rem 1.333rem 1.333rem;
        line-height: 1.2;
        transition: $transition;
    }
}

.rate-article {
    display: inline-block;
    cursor: pointer;
    transition: $transition;
    &__icon {
        height: 50px;
        width: auto;
    }
    &:hover {
        opacity: 0.65;
    }
}

.faq {
    &__item {
        background-color: white;
        border-radius: 1rem;
        box-shadow: 0 0 1rem rgba(black, 0.15);
        margin-bottom: 2rem;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem;
        cursor: pointer;
        transition: $transition;
        .faq__arrow {
            transform: rotate(-180deg);
        }
        &.collapsed {
            &:hover {
                background-color: $lighter;
            }
            .faq__arrow {
                transform: rotate(0deg);
            }
        }
    }
    &__title {
        font-size: $bigSize;
        font-weight: bold;
        color: black;
        line-height: 1.2;
    }
    &__arrow {
        height: 10px;
        width: auto;
        transform-origin: center center;
        transition: $transition;
    }
    &__text {
        font-size: 1.2rem;
        line-height: 1.6;
        padding: 0 2rem 2rem 2rem;
        img {
            max-width: 100% !important;
            height: auto !important;
        }
        p {
            margin: 0;
            padding: 0;
        }
        iframe[src*='youtube'] {
            max-width: 100% !important;
            @media #{$media-lg} {
                width: 100% !important;
                height: 350px !important;
            }
            @media #{$media-sm} {
                width: 100% !important;
                height: 200px !important;
            }
            
        }
    }
}

.gallery {
    &__item {
        height: 190px;
        border-radius: 1rem;
        transition: $transition;
        &:hover {
            opacity: 0.85;
        }
    }
    @media #{$media-sm} {
        &__item {
            height: 175px;
        }
    }
}

.files {
    &__item {
        margin-bottom: 1rem;
    }
    &__link {
        display: inline-flex;
        align-items: flex-start;
    }
    &__icon {
        height: auto;
        width: 2rem;
        min-width: 2rem;
        margin-right: 1rem;
    }
}

.articles {
    &__item {
        &:hover {
            .articles__title {
                color: $primary;
            }
        }
    }
    &__title {
        transition: $transition;
    }
}

.mainpage {
    &__title {
        font-size: 2.4rem;
        font-weight: bold;
        margin-bottom: 2rem;
        line-height: 1.25;
        max-width: 500px;
        p {
            margin-bottom: 0;
        }
        strong {
            font-weight: bold;
        }
    }
    &__text {
        font-size: 1.3rem;
        line-height: 1.5;
        max-width: 500px;
    }
    &__column {
        @media #{$media-lg} {
            margin-bottom: 3rem;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    @media #{$media-sm} {
        &__text,
        .text {
            p,
            span,
            div {
                font-size: $bigSize !important;
            }
        }
    }
}

.page {
    min-height: 50vh;
    padding: 0 0 3rem 0;
    &__card {
        padding: 4rem;
        border: 1px solid #f3f3f3;
        box-shadow: 0px 0px 15px 0px rgba(black, 0.1);
    }
    &--bigpadding {
        padding: 4rem 0;
    }
    &__icon {
        width: 60px;
        max-width: 100%;
        height: auto;
    }
    &__title {
        font-size: 2rem;
        font-weight: bold;
        color: black;
        line-height: 1.3;
        margin-bottom: 1.5rem;
        &--small {
            font-size: 1.6rem;
            line-height: 1.4;
            margin-bottom: 2rem;
        }
    }
    hr {
        margin-bottom: 2rem;
    }
    &__subtitle {
        font-size: $bigSize;
        font-weight: bold;
        color: black;
        line-height: 1.2;
        margin-bottom: 2rem;
        &--small {
            font-size: $mediumSize;
        }
    }
    &__link {
        color: black;
        transition: $transition;
        &:hover {
            color: $primary;
        }
    }
    &__picture {
        height: 300px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 2rem;
    }
    &__date {
        font-size: $normalSize;
        color: $secondary;
    }
    &__text {
        line-height: 1.6;
        font-size: 1.2rem;
    }
    @media #{$media-md} {
        &__picture {
            height: 250px;
        }
    }
    @media #{$media-sm} {
        &__title {
            font-size: $biggerSize;
        }
        &__picture {
            height: 200px;
        }
        &__card {
            padding: 2rem;
        }
    }
}

.courses-page {
    background-color: #f6f6f6;
    min-height: 80vh;
    margin-bottom: 3rem;
}

.course-tile {
    padding: 2rem;
    background-color: white;
    box-shadow: 0 0 10px 0px rgba(black, 0.15);
    border-radius: 1rem;
    position: relative;
    cursor: pointer;
    user-select: none;
    &__badge {
        background-color: white;
        font-size: 1.333rem;
        font-weight: bold;
        color: $primary;
        display: inline-block;
        padding: 0.933rem 1rem;
        border: 3px solid $primary;
        border-radius: 5px;
        &--absolute {
            position: absolute;
            top: -2rem;
            left: 2rem;
        }
    }
    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 0.333rem;
    }
    &__number {
        color: black;
        font-weight: bold;
        font-size: 1.667rem;
        line-height: 1.667rem;
        margin-left: 1rem;
    }
    &__stars {
        display: flex;
        align-items: center;
    }
    &__star {
        height: 2rem;
        width: auto;
        margin-left: 0.4rem;
    }
    &__picture {
        height: 285px;
        background-position: bottom center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    &__type {
        background-color: white;
        font-size: 1.067rem;
        line-height: 1.067rem;
        white-space: nowrap;
        font-weight: bold;
        color: $primary;
        padding: 0.8rem 1rem;
        border: 3px solid $primary;
        border-radius: 8px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1.667rem;
        transition: all 0.3s ease-in-out;
    }
    &__name {
        font-weight: bold;
        color: black;
        font-size: 1.667rem;
        margin-bottom: 2.333rem;
        transition: all 0.3s ease-in-out;
    }
    &__description {
        color: black;
        font-size: 1.333rem;
        p {
            margin-bottom: 0;
            img {
                max-width: 100% !important;
            }
        }
    }
    &__top {
        margin-bottom: 0.6667rem;
    }
    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.6667rem;
    }
    &__cup {
        height: 50px;
        min-width: 60px;
        width: 60px;
        text-align: right;
    }
    &__cup-img {
        height: 100%;
        width: auto;
    }
    &__progress {
        flex-grow: 1;
    }
    &__completed-text {
        height: 1.333rem;
        font-size: 1.067rem;
        margin-bottom: 0.3333rem;
        text-transform: uppercase;
        font-weight: bold;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        letter-spacing: 1px;
        color: $success;
    }
    &__bar {
        width: 15.47rem;
        max-width: 100%;
        height: 4px;
        background-color: #d0d2d3;
    }
    &__bar-progress {
        height: 100%;
        background-color: $primary;
    }
    &__lessons {
        font-size: 1.067rem;
        text-transform: uppercase;
        line-height: 1;
        font-weight: bold;
        transition: all 0.3s ease-in-out;
    }
    &__locker {
        width: 1.667rem;
        min-width: 1.667rem;
        height: auto;
    }
    &__gradient {
        position: absolute;
        bottom: 0;
        height: 2rem;
        opacity: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(360deg, rgba(240, 198, 82, 1) 0%, rgba(255, 255, 255, 0) 2rem);
        z-index: 0;
        transition: all 0.3s ease-in-out;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
    &--completed {
        .course-tile__type {
            color: $success;
            border: 3px solid $success;
        }
        .course-tile__bar-progress {
            background-color: $success;
        }
    }
    &--disabled {
        cursor: default !important;
        .course-tile__star {
            filter: grayscale(100%);
            opacity: 0.25;
        }
        .course-tile__number {
            opacity: 0.25;
        }
        .course-tile__picture {
            filter: grayscale(100%);
            opacity: 0.5;
        }
        .course-tile__type {
            border-color: black;
            color: black;
            opacity: 0.25;
        }
        .course-tile__name,
        .course-tile__description,
        .course-tile__lessons {
            opacity: 0.5;
        }
        .course-tile__gradient {
            display: none;
        }
    }
    &:hover {
        &:not(.course-tile--disabled) {
            .course-tile__gradient {
                opacity: 1;
            }
            .course-tile__type {
                color: white;
                background-color: $success;
            }
            .course-tile__name,
            .course-tile__lessons {
                color: $success;
            }
            &:not(.course-tile--completed) {
                .course-tile__type {
                    background-color: $primary;
                }
                .course-tile__name,
                .course-tile__lessons {
                    color: $primary;
                }
            }
        }
    }
}

.icons {
    &__icon {
        height: 60px;
        width: 60px;
        max-width: 100%;
        margin: 0 auto 1rem auto;
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
    &__description {
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        line-height: 1.4;
        p {
            margin: 0;
        }
    }
}

.tiles {
    &__col {
        display: flex;
        align-items: center;
        @media #{$media-sm} {
            display: block;
        }
    }
    &__icon {
        flex-grow: 0;
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        margin-right: 2rem;
        @media #{$media-sm} {
            margin-right: auto;
            margin-left: auto;
            margin-bottom: 1rem;
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
    &__title {
        font-weight: bold;
        font-size: $biggerSize;
        line-height: 1.2;
        margin-bottom: 1rem;
    }
    &__description {
        font-size: $mediumSize;
        line-height: 1.3;
        p {
            margin: 0;
        }
    }
}

.packages {
    &__col {
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
        &--badge {
            padding-top: 0;
        }
        @media #{$media-xl} {
            padding-top: 0;
        }
    }
    &__body {
        background-color: #f5f5f5;
        border-radius: 0.5rem;
        box-shadow: 0 0 15px 0 rgba(black, 0.25);
        padding: 2rem 1.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        position: relative;
        max-width: 400px;
        margin: auto;
    }
    &__badge {
        position: absolute;
        top: 0;
        display: inline-block;
        border: 3px solid $primary;
        border-radius: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0.5rem;
        text-align: center;
        background-color: white;
        font-size: 1.666rem;
        font-weight: bold;
        color: $primary;
        height: 60px;
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
        top: -30px;
    }
    &__top {
        margin-bottom: 2rem;
        &--badge {
            margin-top: 3rem;
            @media #{$media-xl} {
                margin-top: 1rem;
            }
        }
    }
    &__name {
        font-size: 2.8rem;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 1rem;
        @media #{$media-sm} {
            font-size: 2rem;
        }
    }
    &__value {
        position: relative;
        display: inline-block;
        margin-bottom: 1rem;
    }
    &__price-full {
        font-weight: bold;
        font-size: 6rem;
        line-height: 1;
        @media #{$media-sm} {
            font-size: 4rem;
        }
    }
    &__price-decimal {
        position: absolute;
        top: 0.15rem;
        left: 100%;
        font-size: 2rem;
        margin-left: 0.15rem;
        white-space: nowrap;
        @media #{$media-sm} {
            font-size: 1.5rem;
        }
    }
    &__for {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 1rem;
        @media #{$media-sm} {
            font-size: 1.4rem;
        }
    }
    &__description {
        line-height: 1.4;
        p {
            margin: 0;
        }
    }
    &__button {
        display: inline-block;
        background-color: $success;
        color: white;
        padding: 0.35rem 1.75rem;
        max-width: 100%;
        border-radius: 0.35rem;
        font-weight: bold;
        font-size: 1.333rem;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        cursor: pointer;
        user-select: none;
        border: none;
        outline: none;
        &:hover {
            background-color: darken($success, 5%);
            color: white;
            text-decoration: none;
        }
    }
}

.refprogress {
    font-size: 1.333rem;
    &__heading {
        text-align: center;
        font-size: 2.8rem;
        font-weight: bold;
        margin-bottom: 3rem;
    }
    &__title {
        font-weight: bold;
        margin-bottom: 1rem;
        position: relative;
        display: inline-block;
        padding-top: 65px;
    }
    &__text {
        line-height: 1.4;
    }
    &__icon {
        height: 50px;
        width: 50px;
        object-fit: contain;
        object-position: center bottom;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    @media #{$media-lg} {
        text-align: center;
        &__title {
            display: block;
        }
    }
    @media #{$media-sm} {
        &__heading {
            font-size: 2rem;
            margin-bottom: 2rem;
        }
    }
}

.methodpage {
    margin-bottom: 4rem;
    &__row {
        align-items: center;
    }
    &__col {
        margin-bottom: 4rem;
    }
    &__picture {
        text-align: center;
        margin-bottom: 1.5rem;
    }
    &__caption {
        text-align: center;
        margin-bottom: 1.5rem;
        p {
            margin: 0;
        }
    }
    &__text {
        font-size: 1.2rem;
        line-height: 1.6;
        p {
            margin: 0;
        }
        img {
            max-width: 100% !important;
        }
        iframe[src*='youtube'] {
            max-width: 100% !important;
        }
    }
    &__bigcite {
        padding: 3rem 0;
        text-align: center;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        p {
            margin: 0;
        }
        > div {
            font-size: 2rem;
            text-align: center;
            font-weight: 600;
            display: inline;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            position: relative;
            padding-bottom: 2rem;
            line-height: 1.4;
            p {
                display: inline;
            }
            > span {
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 1.4rem;
                font-weight: normal;
                font-style: italic;
            }
        }
    }
    &__blockcite-item {
        display: flex;
        align-items: flex-start;
        position: relative;
    }
    &__blockcite-cite {
        font-size: 1.2rem;
        line-height: 1.6;
        p {
            display: inline;
        }
    }
    &__blockcite-quote {
        font-size: 70px;
        line-height: 30px;
        font-weight: bold;
        color: #f0c349;
        flex-shrink: 0;
        &--end {
            position: absolute;
            bottom: -1.4rem;
        }
    }
    &__blockcite-author {
        text-align: right;
        margin-top: 2rem;
    }
}

.page-slider {
    padding: 0 2rem;
    &__body {
        padding: 0 1rem;
    }
    &__picture {
        height: 340px;
        background-color: #d1d3d3;
        border-radius: 1rem;
        margin-bottom: 1rem;
        overflow: hidden;
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    &__title {
        text-align: center;
        margin-bottom: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .slick-arrow {
        position: absolute;
        top: 160px;
        color: $primary;
        cursor: pointer;
        .material-icons {
            font-size: 2rem;
        }
        &.prev {
            left: 0;
        }
        &.next {
            right: 0;
        }
    }
    .slick-dots {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        li {
            display: block;
            margin: 5px 5px;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                color: transparent;
                display: block;
                width: 12px;
                height: 12px;
                border: none;
                outline: none;
                background-color: #d1d3d3;
                border-radius: 50%;
                transition: all 0.3s ease-in-out;
            }
            &.slick-active {
                button {
                    background-color: $primary;
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
    @media #{$media-sm} {
        &__picture {
            height: 260px;
        }
        .slick-arrow {
            top: 120px;
        }
    }
}

.subscription {
    border-top: 1px solid $lighter;
    border-bottom: 1px solid $lighter;
    &__logo {
        height: 30px;
        margin-bottom: 0.5rem;
    }
    &__altlogo {
        font-weight: bold;
        font-size: 1.4rem;
        color: $primary;
        margin-bottom: 0.5rem;
    }
    &__price {
        font-weight: bold;
        &--free {
            color: #acacac;
            text-decoration: line-through;
        }
    }
    &__lang {
        font-weight: bold;
        font-size: 0.866rem;
    }
    &__lang-icon {
        width: 20px;
        height: 20px;
        object-fit: contain;
        object-position: center;
        flex-shrink: 0;
        margin-right: 0.5rem;
    }
    &__items {
        margin-top: 2rem;
    }
    &__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1.5rem;
    }
    &__item-icon {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin-right: 1rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
    &__item-text {
        font-size: 0.933rem;
        p {
            margin: 0;
        }
    }
    @media #{$media-md} {
    }
}

.absolute-feedback {
    position: absolute;
    top: calc(100% - 0.5rem);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 0.666rem;
}

.alert-sm {
    font-size: 0.866rem;
    padding: 0.6rem;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.moveup-enter-active {
    transition: transform 0.3s ease-out;
}
.moveup-enter {
    transform: translateY(60px);
}

.movex-enter-active, .movex-leave-active {
    transition: transform 0.3s ease-out;
    position: absolute;
    top: 0;
    left: 0;
}
.movex-enter {
    transform: translateX(100vw);
}
.movex-leave-to {
    transform: translateX(-100vw);
}
