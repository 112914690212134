// Fonts
$font: "Open Sans", "Tahoma", sans-serif; // default font
$font-decor: "Open Sans", "Tahoma", sans-serif; // alternative font
$font-size: 15px; // font size

// colors
$primary: #015cd8;
$secondary: #77787b;
$success: #06c886;
$info: #17a2b8;
$warning: #f0c349;
$danger: #e96155;
$light: #d1d3d4;
$lighter: #f6f6f6;
$lightest: #fcfcfc;
$dark: #000000;

$theme-colors: ();
$theme-colors: map-merge(
    (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "lighter": $lighter,
        "lightest": $lightest,
        "dark": $dark,
    ),
    $theme-colors
);

// Options
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: true;
$enable-responsive-font-sizes: false;
$enable-validation-icons: true;
$enable-deprecation-messages: true;

// font weights
$font-weight-lighter:         100;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          600;
$font-weight-bold:            700;
$font-weight-bolder:          900;
.font-weight-medium {
    font-weight: $font-weight-medium;
}

// Body
//
// Settings for the `<body>` element.
$body-bg: white;
$body-color: black;

// spacers
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        6: (
            $spacer * 4,
        ),
        7: (
            $spacer * 5,
        ),
        8: (
            $spacer * 6,
        ),
        9: (
            $spacer * 7,
        ),
        10: (
            $spacer * 8,
        ),
    ),
    $spacers
);

// Links
//
// Style anchor elements.
$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($primary, 15%);
$link-hover-decoration: none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    xsm: 420px,
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1240px,
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-row-columns: 12;

// Breadcrumbs

$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-margin-bottom: 1rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $primary;
$breadcrumb-active-color: $primary;
$breadcrumb-divider: quote(">");

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
  (
    0: 0,
    15: 15%,
    20: 20%,
    25: 25%,
    33: 33.333%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
    025r: 0.25rem,
    05r: 0.5rem,
    075r: 0.75rem,
    1r: 1rem,
    2r: 2rem,
    3r: 3rem,
    4r: 4rem,
  ),
  $sizes
);

// buttons
$btn-font-size-sm: 0.8rem;
$btn-border-radius: 0.45rem;
$btn-border-radius-lg: 0.5rem;
$btn-border-radius-sm: 0.35rem;
$btn-line-height-sm: 130%;
$btn-transition: color .3s ease-in-out, background-color .3s ease-in-out, border-color .3s ease-in-out, box-shadow .3s ease-in-out;

// dropdown
$dropdown-min-width: 14rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-border-radius: 1rem;
$dropdown-link-hover-bg: $lighter;
$dropdown-link-active-bg: $lighter;
$dropdown-border-color: transparent;
$dropdown-box-shadow: 0 0 6px rgba($light, 0.85);
$dropdown-item-padding-y: 0.5rem;

// modal
$modal-backdrop-bg: $primary;
$modal-backdrop-opacity: .75;

// forms
$input-box-shadow: inset 0 1px 1px rgba(black, .15);
$input-border-radius: .35rem;
$input-border-radius-lg: .35rem;
$input-border-radius-sm: .35rem;
$form-group-margin-bottom: .75rem;
$input-group-addon-bg: white;
$input-group-addon-border-color: transparent;

@import "~bootstrap/scss/bootstrap";
