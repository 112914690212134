// breakpoints
$media-xl: "all and (max-width:1199px)";
$media-lg: "all and (max-width:991px)";
$media-md: "all and (max-width:767px)";
$media-sm: "all and (max-width:575px)";
$media-xsm: "all and (max-width:420px)";

// helpers
$transition: all 0.3s ease-in-out;

.cover { 
    background-position: center center; 
    background-size: cover; 
    background-repeat: no-repeat 
}

.contain { 
    background-position: center center; 
    background-size: contain; 
    background-repeat: no-repeat 
}

.transition {
    transition: $transition;
}

.cursor-pointer {
    cursor: pointer;
}

.invisible {
    opacity: 0;
    font-size: 0;
    position: relative;
    z-index: -1;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
}

.text-xs {
    font-size: 0.733rem;
}

.text-sm {
    font-size: 0.866rem;
}

.text-base {
    font-size: 1rem;
}

.text-lg {
    font-size: 1.266rem;
}

.text-xl {
    font-size: 1.4rem;
}

.text-2xl {
    font-size: 1.5rem;
}

.text-3xl {
    font-size: 1.875rem;
}

.text-4xl {
    font-size: 2.25rem;
}

.text-5xl {
    font-size: 3rem;
}

.text-6xl {
    font-size: 4rem;
}

.leading-3 {
    line-height: .75rem;
}
.leading-4 {
    line-height: 1rem;
}
.leading-5 {
    line-height: 1.25rem;
}
.leading-6 {
    line-height: 1.5rem;
}
.leading-7 {
    line-height: 1.75rem;
}
.leading-8 {
    line-height: 2rem;
}
.leading-9 {
    line-height: 2.25rem;
}
.leading-10 {
    line-height: 2.5rem;
}
.leading-none {
    line-height: 1;
}
.leading-tight {
    line-height: 1.25;
}
.leading-snug {
    line-height: 1.375;
}
.leading-normal {
    line-height: 1.5;
}